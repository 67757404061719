<div class="dark-blue-bg px-lg-5 pb-5 px-2">
  <div class="d-flex flex-row w-100 justify-content-center pt-3">
    <div class="flex-fill my-auto top-sep-white"></div>
    <p class="text-center my-auto mx-3 blue-color" style="font-size: 36px;">ABOUT US</p>
    <div class="flex-fill my-auto top-sep-white"></div>
  </div>

  <div class="d-flex flex-lg-row flex-column justify-content-between box mb-5 mt-3">
    <img src="../../assets/about.png" class="photo">
    <table class="my-lg-auto my-3" style="width: 100%; float: right; height: fit-content;">
      <tr class="w-100">
        <td style="width: 30%;"><p class="ps-lg-3 mb-0">Name</p></td>
        <td style="width: 10%;"><p class="mb-0 mx-auto">:</p></td>
        <td style="width: 60%;"><p class="mb-0">Prathmesh Jagadish Patil</p></td>
      </tr>
      <tr class="w-100">
        <td style="width: 30%;"><p class="ps-lg-3 mb-0">Designation</p></td>
        <td style="width: 10%;"><p class="mb-0 mx-auto">:</p></td>
        <td style="width: 60%;"><p class="mb-0">Founder of Locus Infratec</p></td>
      </tr>
    </table>
  </div>

  <div class="box d-flex flex-row justify-content-center box">
    <div class="d-none flex-row me-3">
      <p class="mb-0">OFFICE ADDRESS</p>
      <p class="ms-3">:</p>
    </div>
    <div class="d-none flex-column">
      <p class="mb-0">
        G07/Vishnu Niwas Bd, Subhash Rd,<br>
        Navapada, Dombivli W - 421202
      </p>
    </div>
    <table style="width: 100%;">
      <tr class="w-100 d-inline-block d-lg-none">
        <td style="width: 30%; vertical-align: top;">
          <p class="mb-0" style="width: fit-content;">Office Address</p>
        </td>
        <td style="width: 10%; vertical-align: top;"><p class="mb-0 mx-auto">:</p></td>
        <td style="width: 60%; vertical-align: top;"><p class="mb-0">G07/Vishnu Niwas Bd, Subhash Rd, Navapada, Dombivli W - 421202</p></td>
      </tr>
      <tr class="w-100 d-lg-flex d-none justify-content-center flex-row">
        <p class="mb-0" style="width: fit-content;">Office Address</p>
        <p class="mb-0 mx-3" style="width: fit-content;">:</p>
        <p class="mb-0" style="width: fit-content;">G07/Vishnu Niwas Bd, Subhash Rd, Navapada, Dombivli W - 421202</p>
      </tr>
    </table>
  </div>
</div>
