<div class="d-flex flex-row w-100 justify-content-center">
  <div class="flex-fill my-auto top-sep-green"></div>
  <p class="text-center title my-auto mx-3 green-color">Why choose US?</p>
  <div class="flex-fill my-auto top-sep-green"></div>
</div>
<div class="d-flex flex-sm-row flex-column justify-content-between px-4 py-4">
  <div class="me-auto image-div">
    <img class="curved-img" src="../../../assets/bedroom/7.jpg">
  </div>
  <div class="bullets">
    <ul class="mb-0 mt-3 mt-sm-0 mx-lg-auto d-flex flex-column justify-content-around h-100" style="list-style: disc; width: max-content;">
      <li class="green-color my-2 my-lg-0"><p class="mb-0">Price match guarantee</p></li>
      <li class="green-color my-2 my-lg-0"><p class="mb-0">45 days move-in guarantee</p></li>
      <li class="green-color my-2 my-lg-0"><p class="mb-0">10 year warranty</p></li>
      <li class="green-color my-2 my-lg-0"><p class="mb-0">146 quality checks</p></li>
    </ul>
  </div>
</div>
<!-- section 4 -->
<div class="d-flex flex-row w-100 justify-content-center" id="homes">
  <div class="flex-fill my-auto top-sep-green"></div>
  <p class="text-center title my-auto mx-3 green-color">A little preview of our homes</p>
  <div class="flex-fill my-auto top-sep-green"></div>
</div>
<div class="owl-carousel d-md-none pt-4 pb-2" id="preview">
  <div class="w-100 mx-auto p-2" *ngFor="let i of images">
    <img [src]="i" style="object-fit: cover; object-position: center; aspect-ratio: 1; width: 100%; border-radius: 10px;">
  </div>
</div>
<div class="d-none d-md-flex w-100 flex-row justify-content-start mx-0 pt-4 pb-2 px-3">
  <div class="w-100 px-3" style="aspect-ratio: 1;" *ngFor="let i of images">
    <img [src]="i" style="object-fit: cover; object-position: center; aspect-ratio: 1; width: 100%; border-radius: 10px;">
  </div>
</div>
<div class="mb-5 d-flex justify-content-center">
  <button class="mx-auto px-3 py-2 green-bg" routerLink="/homes" style="border-radius: 20px; border: none; color: white; font-weight: bold;">Explore now</button>
</div>
