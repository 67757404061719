<div class="dark-blue-bg">
  <div class="mx-auto" style="max-width: 1280px; overflow: auto;">
    <button class="round-gradient-btn mx-3 mt-3" (click)="back()" ><</button>
    <ng-container *ngIf="isInterior">
      <div class="d-none d-md-block">
        <app-gallery [hidden]="page != 0" [head]="'Kitchen'" id="kitchen" [items]="kitchen"></app-gallery>
        <app-gallery [hidden]="page != 0" [head]="'Bedroom'" id="bedroom" [items]="bedroom"></app-gallery>
        <app-gallery [hidden]="page != 1" [head]="'Living Room'" id="livingroom" [items]="livingroom"></app-gallery>
        <app-gallery [hidden]="page != 1" [head]="'Kids Room'" id="kidsroom" [items]="kidsroom"></app-gallery>
        <app-gallery [hidden]="page != 2" [head]="'Washroom'" id="washroom" [items]="washroom"></app-gallery>
        <app-gallery [hidden]="page != 2" [head]="'Epoxy Flooring'" id="epoxy" [items]="epoxy"></app-gallery>
        <app-gallery [hidden]="page != 3" [head]="'Wardrobe'" id="wardrobe" [items]="wardrobe"></app-gallery>
      </div>
      <div class="d-block d-md-none">
        <app-gallery [hidden]="page != 0" [head]="'Kitchen'" id="kitchen" [items]="kitchen"></app-gallery>
        <app-gallery [hidden]="page != 1" [head]="'Bedroom'" id="bedroom" [items]="bedroom"></app-gallery>
        <app-gallery [hidden]="page != 2" [head]="'Living Room'" id="livingroom" [items]="livingroom"></app-gallery>
        <app-gallery [hidden]="page != 3" [head]="'Kids Room'" id="kidsroom" [items]="kidsroom"></app-gallery>
        <app-gallery [hidden]="page != 4" [head]="'Washroom'" id="washroom" [items]="washroom"></app-gallery>
        <app-gallery [hidden]="page != 5" [head]="'Epoxy Flooring'" id="epoxy" [items]="epoxy"></app-gallery>
        <app-gallery [hidden]="page != 6" [head]="'Wardrobe'" id="wardrobe" [items]="wardrobe"></app-gallery>
      </div>
    </ng-container>
    <div [hidden]="isInterior">
      <app-gallery [head]="'Our Homes'" [items]="homes"></app-gallery>
    </div>
  </div>
  <div class="mt-3 mt-md-0 d-flex flex-row flex-wrap justify-content-center" *ngIf="isInterior">
    <button class="m-3" [ngClass]="page==0?'selected-btn':'round-gradient-btn'" (click)="updatePage(0)">1</button>
    <button class="m-3" [ngClass]="page==1?'selected-btn':'round-gradient-btn'" (click)="updatePage(1)">2</button>
    <button class="m-3" [ngClass]="page==2?'selected-btn':'round-gradient-btn'" (click)="updatePage(2)">3</button>
    <button class="m-3" [ngClass]="page==3?'selected-btn':'round-gradient-btn'" (click)="updatePage(3)">4</button>
    <button class="m-3 d-md-none" [ngClass]="page==4?'selected-btn':'round-gradient-btn'" (click)="updatePage(4)">5</button>
    <button class="m-3 d-md-none" [ngClass]="page==5?'selected-btn':'round-gradient-btn'" (click)="updatePage(5)">6</button>
    <button class="m-3 d-md-none" [ngClass]="page==6?'selected-btn':'round-gradient-btn'" (click)="updatePage(6)">7</button>
  </div>
</div>
