<div class="d-flex flex-column main-div">
  <div class="px-lg-5 px-md-3 px-2 d-flex flex-row w-100 justify-content-center" style="padding-top: 30px;">
    <div class="flex-fill my-auto top-sep-white"></div>
    <p class="text-center my-auto mx-md-3 mx-2 title">Our client's review</p>
    <div class="flex-fill my-auto top-sep-white"></div>
  </div>
  <div class="px-lg-5 px-3 py-5 d-flex flex-column w-100 justify-content-center">
    <div class="row w-100 mx-0">
      <div class="col-6 px-2 ps-lg-3 pe-lg-4 border-right">
        <p class="mb-0 mt-3 name">Jyotsana Gupta</p>
        <p class="mb-0 green-color location">Mumbai, Maharashtra</p>
        <p class="mb-3 desc">Locus Homes took special care of our requirements and created a modern traditional home for us</p>
      </div>
      <div class="col-6 ps-3 ps-lg-4">
        <p class="mb-0 mt-3 name">Omkar Khamitkar</p>
        <p class="mb-0 green-color location">Mumbai, Maharashtra</p>
        <p class="mb-3 desc">Locus Homes has made our flat into a home which we always wanted.</p>
      </div>
    </div>
    <div class="row w-100 mx-0">
      <div class="col-6 px-2 ps-lg-3 pe-lg-4 border-right">
        <p class="mb-0 mt-3 name">Kartiki Thombre</p>
        <p class="mb-0 green-color location">Mumbai, Maharashtra</p>
        <p class="mb-3 desc">Locus Homes understood us and gave us our home of dreams</p>
      </div>
      <div class="col-6 ps-3 ps-lg-4">
        <p class="mb-0 mt-3 name">Nikita</p>
        <p class="mb-0 green-color location">Mumbai, Maharashtra</p>
        <p class="mb-3 desc">Getting our home interior done by Locus Homes is one of the best decision we had ever taken</p>
      </div>
    </div>
  </div>
</div>
