<div [formGroup]="contactForm" class="d-flex justify-content-center" style="background-color: grey;">
  <div class="p-5 d-flex flex-column mt-5 mb-5 dark-blue-bg">
    <input type="text" placeholder="Name" class="box" formControlName="name" >
    <input type="text" placeholder="Email Id" class="box" formControlName="email">
    <input type="number" placeholder="Phone Number" class="box" formControlName="phone">
    <div class="d-flex flex-row justify-content-start align-items-center my-2">
      <input type="checkbox" class="my-1" style="width: 20px; height: 20px;" formControlName="whatsapp">
      <label class="ps-3 my-auto blue-color" style="line-height: 24px; font-weight: bold; font-size: 20px;">Send me updates on Whatsapp</label>
    </div>
    <p class="mb-0" *ngIf="error" style="color: red; font-style: italic; font-weight: bold;">{{ errorMsg }}</p>
    <button class="green-btn" (click)="connect()">Consult Now</button>
    <p class="blue-color">By submitting form, you agree to the <span style="color: white;">privacy policy</span> and <span style="color: white;">terms & conditions</span></p>
  </div>
</div>
