<div class="w-100" style="background-color: #172128;">
  <div class="mx-auto" style="width: 100vw; max-width: 100%;">
    <div #carousel id="slideshow" class="carousel slide" data-bs-interval="3000" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <ng-container *ngFor="let item of images; let i = index">
          <button (click)="check(i)" [ngClass]="i==0 ? 'active' : '' " data-bs-target="#slideshow" [attr.data-bs-slide-to]="i" [attr.aria-label]="'Slide'+i"></button>
        </ng-container>
      </div>
      <div class="carousel-inner h-100">
        <ng-container *ngFor="let item of images; let i = index">
          <div class="carousel-item h-100" style="position: relative;" [ngClass]="i==0 ? 'active' : '' ">
            <div class="h-100 w-100" style="opacity: 0.6;" >
              <img width="100%" class="carousel-img" [src]="item">
            </div>
            <div style="position: absolute; top: 15%; left: 10%;" *ngIf="i==0">
              <p class="mb-0">Relax your Mind</p>
              <p class="mb-0">Body and Soul with</p>
              <p class="mb-2">LOCUS INFRATEC</p>
              <button class="consult-btn px-3 py-1" routerLink="/consult">Consult Now</button>
            </div>
            <ng-container *ngIf="i>0">
              <p class="w-100 text-center" style="position: absolute; bottom: 10%;">{{ banner[i-1] }}</p>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- <div class="owl-carousel owl-theme" id="main-carousel" style="height: calc(100vh - 64px)">
      <div class="w-100 h-100" *ngFor="let item of images; let i=index;">
        <img [src]="item" style="object-fit: contain; object-position: center; height: 100%; width: auto;">
        <div style="position: absolute; top: 15%; left: 10%;" *ngIf="i==0">
          <p class="mb-0">Relax your Mind</p>
          <p class="mb-0">Body and Soul with</p>
          <p class="mb-2">LOCUS INFRATEC</p>
          <button class="consult-btn px-3 py-1" routerLink="/consult">Consult Now</button>
        </div>
        <ng-container *ngIf="i>0">
          <p class="w-100 text-center" style="position: absolute; bottom: 10%;">{{ banner[i-1] }}</p>
        </ng-container>
      </div>
    </div> -->
    <app-slideshow></app-slideshow>
    <app-preview></app-preview>
    <app-pointer></app-pointer>
    <app-review></app-review>
  </div>
</div>
