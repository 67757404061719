<div class="d-flex mx-auto flex-row w-100 justify-content-center mt-5 mb-0">
  <div class="flex-fill my-auto top-sep-green"></div>
  <p class="text-center my-auto mx-3 green-color title">One-stop shop for all things home interiors</p>
  <div class="flex-fill my-auto top-sep-green"></div>
</div>
<div class="owl-carousel d-md-none pt-4 pb-2 w-100">
  <div class="w-100 mx-auto p-2" *ngFor="let i of images">
    <img [src]="i" style="object-fit: cover; object-position: center; aspect-ratio: 1; width: 100%; border-radius: 10px;">
  </div>
</div>
<div class="d-none d-md-flex w-100 flex-row justify-content-start mx-0 pt-4 pb-2 px-3">
  <div class="w-100 px-3" style="aspect-ratio: 1;" *ngFor="let i of images">
    <img [src]="i" style="object-fit: cover; object-position: center; aspect-ratio: 1; width: 100%; border-radius: 10px;">
  </div>
</div>
<div class="mb-5 d-flex justify-content-center">
  <button class="mx-auto px-3 py-2 green-bg" routerLink="/interior" style="border-radius: 20px; border: none; color: white; font-weight: bold;">Explore now</button>
</div>
