<div class="navbar">
  <div class="mx-0 mx-md-3 d-flex flex-column flex-md-row justify-content-start h-100 w-100" style="background-color: transparent;">
    <img src="../../assets/logo/logo-banner.png" routerLink="/home" routerLinkActive="router-link-active" class="my-md-auto my-0 mx-md-2 ms-2 me-0 nav-image">
    <div class="d-flex flex-row justify-content-md-start mx-md-1 justify-content-center mx-auto" style="min-height: fit-content;">
      <a class="nav-link disabled d-none d-md-block">|</a>
      <a class="nav-link" (click)="navigateHome()">How it works</a>
      <a class="nav-link dropdown d-none d-md-block" id="offerings" data-toggle="dropdown">Offerings
        <div class="dropdown-content">
          <div class="mx-0 pt-3 px-md-1 px-0 row d-none d-md-flex flex-wrap" style="width: fit-content;">
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('kitchen', 0)">
              <div style="height: 100px;">
                <img src="../../assets/kitchen/3.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Kitchen</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('bedroom', 0)">
              <div style="height: 100px;">
                <img src="../../assets/bedroom/11.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Bedroom</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('kidsroom', 0)">
              <div style="height: 100px;">
                <img src="../../assets/kids room/11.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Kids Room</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('livingroom', 0)">
              <div style="height: 100px;">
                <img src="../../assets/living room/3.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Living Room</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('washroom', 0)">
              <div style="height: 100px;">
                <img src="../../assets/washroom/4.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Washroom</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('epoxy', 0)">
              <div style="height: 100px;">
                <img src="../../assets/epoxy flooring/9.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Epoxy Flooring</p>
            </div>
            <div class="col-md-3 p-3 mb-3" (click)="navigateInterior('wardrobe', 0)">
              <div style="height: 100px;">
                <img src="../../assets/wardrobe/9.jpg" style="object-fit: cover;" height="100%" width="100%">
              </div>
              <p style="line-height: 30px;">Wardrobe</p>
            </div>
          </div>
          <div class="mx-0 pt-3 px-md-1 px-0 d-flex flex-column d-none" style="width: fit-content;">
            <div class="m-2" style="width: auto;" (click)="navigateInterior('kitchen', 1)">
              <div style="height: 50px;">
                <img src="../../assets/kitchen/3.jpg" style="object-fit: contain;" height="100%" width="auto">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Kitchen</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('bedroom', 1)">
              <div style="height: 50px;">
                <img src="../../assets/bedroom/11.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Bedroom</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('kidsroom', 1)">
              <div style="height: 50px;">
                <img src="../../assets/kids room/11.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Kids Room</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('livingroom', 1)">
              <div style="height: 50px;">
                <img src="../../assets/living room/3.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Living Room</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('washroom', 1)">
              <div style="height: 50px;">
                <img src="../../assets/washroom/4.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Washroom</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('epoxy', 1)">
              <div style="height: 50px;">
                <img src="../../assets/epoxy flooring/9.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Epoxy Flooring</p>
            </div>
            <div class="m-2" style="width: auto;" (click)="navigateInterior('wardrobe', 1)">
              <div style="height: 50px;">
                <img src="../../assets/wardrobe/9.jpg" style="object-fit: contain; width: auto;" height="100%">
              </div>
              <p style="line-height: 1.25; font-size: xx-small;">Wardrobe</p>
            </div>
          </div>
        </div>
      </a>
      <a class="nav-link dropdown d-md-none" (click)="navigateInterior('kitchen', 1)">Offerings</a>
      <a class="nav-link dropdown">Locality
        <div class="dropdown-content" style="width: fit-content; line-height: 36px;">
          <p *ngFor="let item of cities" class="mx-2 mb-0 cityItem">{{ item }}</p>
        </div>
      </a>
      <a class="nav-link" routerLink="/homes">Locus Homes</a>
      <a class="nav-link" routerLink="/about">More</a>
    </div>
  </div>
</div>
