<div class="w-100 dark-blue-bg">
  <div class="mx-auto d-flex flex-column justify-content-center pt-3 pt-lg-5 px-3 px-lg-5" style="max-width: 1280px;">
    <img src="../../assets/logo/logo-banner.png" style="height: 64px; width: fit-content; margin-bottom: 36px;">
    <div class="d-flex flex-md-row flex-column justify-content-between">
      <div class="d-flex flex-row justify-content-start" style="width: 40%;">
        <a class="pe-3" href="https://api.whatsapp.com/send/?phone=919152565665"><img src="../../assets/logo/whatsapp.png" style="height: 56px; width: 56px;"></a>
        <a class="pe-3" href="https://www.instagram.com/locusinfratec_"><img src="../../assets/logo/instagram.png" style="height: 56px; width: 56px;"></a>
        <a class="pe-3" href="https://www.facebook.com/Locus-Infratec-113449884573632"><img src="../../assets/logo/facebook.png" style="height: 56px; width: 56px;"></a>
      </div>
      <div class="d-flex d-md-none flex-column justify-content-start mt-4">
        <p><a routerLink="/about">About Us</a></p>
        <p>Contact Us</p>
        <p>Privacy</p>
      </div>
      <div class="d-md-flex d-none flex-column justify-content-start" style="margin-top: -36px; width: 30%;">
        <p><a routerLink="/about">About Us</a></p>
        <p>Contact Us</p>
        <p>Privacy</p>
      </div>
      <div class="d-md-block d-none" style="margin-top: -60px; width: 30%;">
        <p><b>Get in touch</b></p>
        <br>
        <p><b>Call Us</b><br><a href="tel:+919820820089">9820820089</a></p>
        <p><b>Email Us</b><br><a href="mailto:locus.infratec@gmail.com?Subject=Enquiry">locus.infratec@gmail.com</a></p>
        <br>
        <p>
          <b>OFFICE ADDRESS</b><br>
          G07/Vishnu Niwas Bldg,<br>
          Subhash road,<br>
          Navapada, Dombivli W - 421202
        </p>
      </div>
      <div class="d-block d-md-none">
        <p><b>Get in touch</b></p>
        <br>
        <p><b>Call Us</b><br><a href="tel:+919820820089">9820820089</a></p>
        <p><b>Email Us</b><br><a href="mailto:locus.infratec@gmail.com?Subject=Enquiry">locus.infratec@gmail.com</a></p>
        <br>
        <p>
          <b>OFFICE ADDRESS</b><br>
          G07/Vishnu Niwas Bldg,<br>
          Subhash road,<br>
          Navapada, Dombivli W - 421202
        </p>
      </div>
    </div>
    <hr style="background-color: whitesmoke; height: 3px;">
    <div class="w-100 d-flex flex-row justify-content-start">
      <p style="width: 40%;">Locations</p>
      <p class="w-50">Offerings</p>
    </div>
  </div>
  <div class="mx-auto d-flex flex-column justify-content-center px-3 px-lg-5 pb-lg-5">
    <hr style="background-color: whitesmoke; height: 3px;">
    <p class="mx-auto" style="width: fit-content; color: #ffffff88;">@ 2021 LocusInfratec.com All Rights Reserved</p>
  </div>
</div>
