<div class="d-flex flex-row w-100 justify-content-center mt-3 mb-4">
  <div class="flex-fill my-auto" style="border-top: 2px solid white;"></div>
   <p class="text-center my-auto mx-3 blue-color" style="font-size: 36px; text-transform: uppercase;">{{ head }}</p>
  <div class="flex-fill my-auto" style="border-top: 2px solid white;"></div>
 </div>
<div class="mt-4 row mx-4">
  <ng-container *ngFor="let i of items">
    <div class="col-6 col-md-4 p-md-4 p-2">
      <div class="w-100" style="aspect-ratio: 1; z-index: 1;">
        <a class="imageLink" [href]="i" target="_blank">
          <img [src]="i" height="100%" width="100%" style="object-fit: cover; border-radius: 10px; opacity: 0.6;">
        </a>
      </div>
    </div>
  </ng-container>
</div>
